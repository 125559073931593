<template id="">
  <div>
    <div class="login">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h4 class="uk-text-background">仮登録を完了しました</h4>
      <p>
        ご入力いただいたメールアドレスに<br />
        本登録用のメールを送信しました。<br />
        メールをご確認いただき、メールに記載されたURLをクリックして、登録手続きをお進めください。<br />
        ※記載されたURLは24時間有効です。
      </p>
      <!--div class="uk-width-1-1">
      <div class="uk-margin">
        <button :disabled="$store.state.common.sServerProcessing" class="uk-button uk-button-secondary uk-width-1-1" type="button" @click="$router.back()">戻る</button>
      </div>
    </div-->
    </div>
  </div>
</template>

<script type="text/javascript">
document.title = "仮登録完了";
</script>
